<template>
<div>
    all vendors
    </div>    
</template>

<script>
export default {
    Name:'AllVendors'
}
</script>