<template>
  <div>
    <div class="main-wrapper" style="">
      <div class="main-content right-chat-active">
        <div class="middle-sidebar-bottom">
          <div class="middle-sidebar-left">
            <div class="row">
              <div>
                <div class="row">
                  <div class="col-sm-12 text-center">
                    <div class="card w-100 shadow-xss rounded-xxl border-0">
                      <div>
                        <div class="row">
                          <div class="p-4 pt-0">
                            <div class="payment">
                              <div class="payment_header">
                                <div class="check">
                                  <i
                                    class="fa fa-exclamation-triangle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                              <div class="content">
                                <h1 class="text-left font-xss fw-500">
                                  Restroworld Help Centre: Get All Solutions
                                  Here
                                </h1>
                                <p class="font-xssss fw-500 text-justify">
                                  Once you place your order on any online
                                  shopping store, the next obvious thing to do
                                  is wait for your product to arrive. This wait
                                  can be quite anxiety-ridden if you do not get
                                  updates about your order or do not receive
                                  support post-delivery of your order. However,
                                  with the Restroworld Help Centre, your wait
                                  becomes exciting, and your shopping experience
                                  becomes joyful, thanks to all the support it
                                  provides related to your order. With websites,
                                  like Restroworld, the entire shopping
                                  experience has gone through a major change.
                                  Now, you can conveniently shop any time, from
                                  anywhere, and anything that you want. These
                                  websites are one-stop destinations for all
                                  your needs and requirements. From skincare
                                  products to home appliances and groceries to
                                  baby care essentials, everything is just a few
                                  clicks away. These websites provide you
                                  convenience, at the same time, a bitter
                                  experience can make online shopping a
                                  nightmare for many. To ensure that your
                                  shopping experience is delightful, the
                                  Restroworld Help centre support offers every
                                  assistance that you need. From reporting
                                  specific delivered product-related issues to
                                  letting you manage your orders, the
                                  Restroworld Helpcentre has solutions to all
                                  your worries related to your orders.
                                  Furthermore, if you do not find a solution to
                                  your queries here, then you can use the
                                  Restroworld Help Centre number to get your
                                  issues solved. Keep reading to know more about
                                  Restroworld Help Centre and what all
                                  assistance you get here:
                                </p>
                                <h1 class="text-left font-xss fw-500 p-0">
                                  24x7 Customer Care Support
                                </h1>
                                <p class="font-xssss fw-500 text-justify">
                                  You can 24x7 Customer Care Support on the
                                  Restroworld Help Centre. Any query or issue
                                  that you may possibly have while shopping on
                                  Restroworld is taken care here. This page is
                                  easy to navigate, and you can get support
                                  almost immediately. Once you log onto your
                                  Restroworld account, this page shows you your
                                  recent orders and let you report any issue. By
                                  clicking on the specific order, you can raise
                                  your query. It also has a chat option to
                                  ensure that your queries and issues are taken
                                  care of. Similarly, there are other options on
                                  this page that are created to assist you and
                                  to make your shopping experience hassle-free.
                                  You can get support any time and get a
                                  satisfactory solution to your queries and
                                  issues within minutes.
                                </p>
                                <h1 class="text-left font-xss fw-500 p-0">
                                  Types and Topics of Support in Restroworld
                                  Help Centre
                                </h1>
                                <p class="font-xssss fw-500 text-justify mb-1">
                                  Apart from helping you with your orders and/or
                                  your delivered product-related issues, you can
                                  find various other support at Restroworld Help
                                  Centre. You can select from three types of
                                  issues here - help with your issues, help with
                                  your order, and help with other issues. You
                                  can track your orders here, manage your
                                  orders, get help with your returns and refunds
                                  issues, and even get help related to various
                                  other issues, such as offers, payment,
                                  Restroworld Plus, etc. There are also details
                                  about specific help topics, such as
                                  cancellations and returns, wallet, insurance,
                                  Restroworld Quick, SuperCoins, Gift Card, etc.
                                  available here. So, log on to your Restroworld
                                  account and shop without hassles and with
                                  complete help and support.
                                </p>
                                <p class="font-xssss fw-500 text-justify mb-2">
                                  The Restroworld Help Centre is available on
                                  the Restroworld site to help every Restroworld
                                  customer with any grievance that they may
                                  have. You can find solutions regarding the
                                  tracking of your order. It will also help edit
                                  your delivery date or address and more. All
                                  your issues will be addressed at any time of
                                  the day or night (24/7 service). This way, you
                                  can immediately get your grievances addressed.
                                  You can also get help regarding returns and
                                  refunds and many other issues through the
                                  Restroworld Help Centre. In case you're not
                                  satisfied with the solution given, you can
                                  seek further assistance. You can get in touch
                                  with a support assistant via the Restroworld
                                  Help Centre number. The next time you have any
                                  issue with your order or if you want more
                                  clarity regarding payment options, Restroworld
                                  Plus, account-related queries, and more, you
                                  can access Restroworld Help Centre support for
                                  further information. This way, you can shop
                                  without worry and have a satisfying shopping
                                  experience. The support centre will do all
                                  that it can to address your grievance until
                                  you’re completely satisfied. So, no matter the
                                  nature of your grievance, you must get in
                                  touch with the support forum. This way, you
                                  won’t have to compromise in any way.
                                </p>

                                <router-link to="/Home/User"
                                  >Go to Home</router-link
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="col-xl-3 col-xxl-2 col-lg-3 ps-lg-0">
                     
                     <div class="card w-100 shadow-xss rounded-xxl border-0 mb-3 ml-3">
                         sdfdsfdsfdsfds
                     </div>
                  </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  Name: "Fail",
};
</script>


<style scoped>
.content {
  text-align: center;
}

.content h1 {
  font-size: 25px;
  padding-top: 25px;
}

.content a {
  width: 200px;
  height: 35px;
  color: #fff;
  border-radius: 30px;
  padding: 5px 10px;
  background: #f01b1b;
  transition: all ease-in-out 0.3s;
}

.content a:hover {
  text-decoration: none;
  background: #000;
}
</style>