<template>
  <div>
    <div class="main-wrapper" style="">
      <div class="main-content right-chat-active">
        <div class="middle-sidebar-bottom">
          <div class="middle-sidebar-left">
            <div class="row">
              <div>
                <div class="row">
                  <div class="col-sm-12 text-center">
                    <div class="card w-100 shadow-xss rounded-xxl border-0">
                      <div>
                        <div class="row">
                          <div class="p-4 pt-0">
                            <div class="payment">
                              <div class="payment_header">
                                <div class="check">
                                  <i
                                    class="fa fa-exclamation-triangle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                              <div class="content">
                                <h1 class="text-left font-xss fw-500">
                                  Privacy Policy
                                </h1>
                                <p class="font-xssss fw-500 text-justify mb-1">
                                  The privacy policy contains personal
                                  information collected on our website, it's not
                                  used by any third party or doesn't apply on
                                  any other site. It's a legal statement that
                                  justifies the limitless information gathered
                                  at a particular point of time and is secured.
                                  By accessing the website, you're agreed with
                                  our professional and personal care services,
                                  moreover, believe in our non-disclosure
                                  policies.
                                </p>
                                <p class="font-xssss fw-500 text-justify mb-1">
                                  Furthermore, you're agreed to the terms
                                  contained in our statements or any document
                                  incorporated by reference, it does not matter
                                  if you're registered with a digital agency
                                  network or not.
                                </p>
                                <p class="font-xssss fw-500 text-justify mb-1">
                                  By disagreeing with our protocols we have the
                                  right to hide our services and conditions from
                                  you so that you'll not be in the efficient
                                  mode of enjoying our benefits.
                                </p>
                                <p class="font-xssss fw-500 text-justify">
                                  In addition to this, the privacy policy of
                                  RESTROWORLD covers the confidential
                                  information we collect from our visitors and
                                  users of (site URL) and also our related
                                  research and reviews system. You're free to
                                  switch your click if you're not agreed with
                                  our services and concerning agreements.
                                </p>

                                <h1 class="text-left font-xss fw-500 p-0">
                                  Your Rights
                                </h1>
                                <p class="font-xssss fw-500 text-justify mb-1">
                                  You're the owner of the market, therefore, you
                                  have certain rights to take your quick
                                  decision if you find any negative vibes while
                                  sharing your confidential info to our website
                                  under the General Data Protection Regulation
                                  (GDPR). For your preference here's the list of
                                  our queries:
                                </p>
                                <h1 class="text-left font-xsss fw-500 m-0 p-0">
                                  Information about you
                                </h1>
                                <p class="font-xssss fw-500 text-justify mb-1">
                                  While glancing at your interest we generally
                                  ask about your participation to use our
                                  services, such as
                                </p>
                                <p class="font-xssss fw-500 text-justify mb-1">
                                  At the time of creating your account you're
                                  asked to provide us your name, address, and
                                  email address, plus to use our website
                                  location friendly we ask to provide additional
                                  information such as your location, bio,
                                  industry, picture, and cellphone number, also
                                  if you feel someone by your information has
                                  created your account you can ask us to delete
                                  it.
                                </p>

                                <p class="font-xssss fw-500 text-justify mb-1">
                                  For the review procedure, we'll ask for the
                                  company's name, address, official mail
                                  address, and this information will not be
                                  public.
                                </p>
                                <p class="font-xssss fw-500 text-justify mb-1">
                                  If you're interested in purchasing our
                                  services then after your general interest
                                  we'll display your brand's name as a client at
                                  our website
                                </p>

                                <h1 class="text-left font-xsss fw-500 m-0 p-0">
                                  Information about others
                                </h1>
                                <p class="font-xssss fw-500 text-justify mb-1">
                                  By information about others we mean to provide
                                  your clients or business partner's information
                                  such as name, address, email address, contact
                                  number to support our reference check, the
                                  info is only asked for review procedure in the
                                  future.
                                </p>

                                <h1 class="text-left font-xss fw-500 p-0">
                                  How Do We Use Your Information
                                </h1>
                                <p class="font-xssss fw-500 text-justify">
                                  The automated system is accessible to collect
                                  your information after your provided
                                  information to customize our website. We
                                  collect the same for your convenience as
                                  you're once registered you're free from
                                  disturbances pings, the details are used to
                                  offer you stand out services, provide you the
                                  effective content, to communicate you via
                                  email or verbal mode, plus sends you
                                  imperative emails to make you aware about
                                  efficient features and services lists, and a
                                  lot more.
                                </p>
                                <h1 class="text-left font-xsss fw-500 m-0 p-0">
                                  Communications exchange
                                </h1>
                                <p class="font-xssss fw-500 text-justify mb-1">
                                  When you registered yourself as our member
                                  we'll be sharing all qualitative and
                                  beneficiary details for your study to
                                  stimulate your keen interest via email. You're
                                  free to change your personal details while
                                  visiting our website or contacting us at
                                  (email address).
                                </p>

                                <h1 class="text-left font-xsss fw-500 m-0 p-0">
                                  Shares the information by Facebook and Google
                                </h1>
                                <p class="font-xssss fw-500 text-justify mb-1">
                                  If you allow for PII (Personally Identifiable
                                  Information) with third parties which is said
                                  as a lookalike audience marketing then we may
                                  share your business information for this type
                                  of marketing including Facebook, Google
                                  Analytics, Google Search Console, Google
                                  AdWords, or any other Google tools.
                                </p>

                                <h1 class="text-left font-xss fw-500 p-0">
                                  Security
                                </h1>
                                <p class="font-xssss fw-500 text-justify mb-1">
                                  Your personal data information is our
                                  responsibility hence we conduct several
                                  parameters for the security but ensure that no
                                  security measures are 100% accurate or
                                  healthier and no method of transmission is
                                  transparent. We do a lot to secure your
                                  details but as we said nothing is accurate or
                                  we cannot provide you any warranty so do it at
                                  your own risk.
                                </p>

                                <p class="font-xssss fw-500 text-justify mb-1">
                                  You're free to report security violations to
                                  us at our mail ID (address).
                                </p>
                                <p class="font-xssss fw-500 text-justify mb-1">
                                  Our Email Policy: We'll not send, sell, or
                                  trade your email address to a third party
                                  without your permission so feel safe with us.
                                  If you receive an email from RESTROWORLD
                                  asking for your credit cards, banking details,
                                  or any other financial information, just
                                  neglect and ignore the mail as we never ask
                                  via email for this information
                                </p>

                                <h1 class="text-left font-xss fw-500 p-0">
                                  How To Reach Us For Privacy Concerns
                                </h1>
                                <p class="font-xssss fw-500 text-justify mb-2">
                                  If you've any query regarding the procedures,
                                  practices, or in the use of our website, or
                                  would like to bring in any changes then feel
                                  free to contact us via mail or phone.
                                </p>
                                <router-link to="/Home/User"
                                  >Go to Home</router-link
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="col-xl-3 col-xxl-2 col-lg-3 ps-lg-0">
                     
                     <div class="card w-100 shadow-xss rounded-xxl border-0 mb-3 ml-3">
                         sdfdsfdsfdsfds
                     </div>
                  </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  Name: "Fail",
};
</script>


<style scoped>
.content {
  text-align: center;
}

.content h1 {
  font-size: 25px;
  padding-top: 25px;
}

.content a {
  width: 200px;
  height: 35px;
  color: #fff;
  border-radius: 30px;
  padding: 5px 10px;
  background: #f01b1b;
  transition: all ease-in-out 0.3s;
}

.content a:hover {
  text-decoration: none;
  background: #000;
}
</style>