<template>
    <div class="custom-signupImage w-100">
      <div
        class=" d-flex flex-column justify-content-center align-items-center my-5"
      >
        <h1 class="fw-bold " style="font-size: 3rem; margin-top: 5%;"><span class="text-danger">who</span> we are ?</h1>
        <h4 class="fw-semibold text-center">
          First Global Social Media Platform For Live Graba-Class
        </h4>
      </div>
  
      <div class="container-fluid mb-5w-100 my-5">
        <div class="row g-5 align-items-start">
          <div class="col-md-6 p-4 text-right bg-body p-0 rounded-4">
            <img
              src="../assets/img1.jpg"
              class="img-fluid custom-img-design rounded-4"
              alt="ManEatingImage"
            />
          </div>
          <div class="col-md-6 p-4">
            <div
              class=" d-flex flex-column justify-content-center align-items-center"
            >
              <p class="fw-light fs-2" >
                The restaurant landscape has witnessed a transformative shift with the advent of Restroworld, a pioneering global platform tailored exclusively for the restaurant industry. In a digital era where connectivity defines success, Restroworld emerges as a game-changer, fostering a thriving ecosystem for both restaurateurs and vendors.In conclusion, Restroworld isn't just a platform; it's a movement reshaping the restaurant industry. It encapsulates the essence of globalization, collaboration, and progress, propelling businesses.
              </p>
            </div>
          </div>
        </div>
      </div>
  
      <div class="container-fluid mb-5w-100 my-5">
        <div class="row g-0 align-items-start flex-row-reverse">
          <div class="col-md-6 p-4 bg-body p-0 rounded-4">
            <img
              src="../assets/img2.jpg"
              class="img-fluid custom-img-design rounded-4"
              alt="ManEatingImage"
            />
          </div>
          <div class="col-md-6 p-4 text-end">
            <div class="">
              <p class="fw-light fs-2 " >
                At its core, Restroworld stands as an unparalleled
community, serving as a nexus where every facet of the restaurant industry converges. Whether you're a seasoned restaurateur seeking innovative solutions or a vendor aiming to expand your clientele, Restroworld offers an expansive marketplace and networking haven. Looking ahead, Restroworld envisions an even more interconnected and vibrant ecosystem. Future iterations promise enhanced Al-driven matchmaking, predictive analytics, and augmented
              </p>
            </div>
          </div>
        </div>
      </div>
  
      <div class="container-fluid mb-5w-100 my-5">
        <div class="row g-0 align-items-start">
          <div class="col-md-6 p-4 text-right bg-body p-0 rounded-4">
            <img
              src="../assets/img3.jpg"
              class="img-fluid custom-img-design rounded-4"
              alt="ManEatingImage"
            />
          </div>
          <div class="col-md-6 p-4">
            <div class="">
              <p class="fw-light fs-2" >
                Imagine a digital agora where restaurateurs explore a myriad of vendors worldwide, encompassing diverse solutions ranging from technology to ingredients, and marketing to equipment. This dynamic marketplace not only offers a treasure trove of options but also cultivates a global synergy, enabling restaurateurs to curate bespoke solutions tailored to their specific needs.
              </p>
            </div>
          </div>
        </div>
      </div>
  
      <div class="container-fluid mb-5w-100 my-5">
        <div class="row g-0 align-items-start flex-row-reverse">
          <div class="col-md-6 p-4 bg-body p-0 rounded-4">
            <img
              src="../assets/img4.jpg"
              class="img-fluid custom-img-design rounded-4"
              alt="ManEatingImage"
            />
          </div>
          <div class="col-md-6 p-4 text-end">
            <div class="">
              <p class="fw-light fs-2" >
                On the flip side, vendors gain access to an extensive network of restaurants spanning continents. From established chains to cozy local eateries, the platform acts as a gateway for vendors to showcase their offerings to a diverse clientele, fostering growth and collaborations across borders. The platform also pledges to champion social causes, supporting initiatives that empower communities and foster positive change.
              </p>
            </div>
          </div>
        </div>
      </div>
  
      <div class="container-fluid mb-5w-100 my-5">
        <div class="row g-0 align-items-start">
          <div class="col-md-6 p-4 text-right bg-body p-0 rounded-4">
            <img
              src="../assets/img5.jpg"
              class="img-fluid custom-img-design rounded-4"
              alt="ManEatingImage"
            />
          </div>
          <div class="col-md-6 p-4">
            <div class="">
              <p class="fw-light fs-2" >
                Restroworld, in essence, redefines the dynamics of the restaurant industry by amplifying connectivity and efficiency. By amalgamating cutting-edge technology and a user-centric approach, it streamlines operations and catalyzes innovation. Restaurant owners find solace in the ease of discovering, vetting, and engaging with vendors, while vendors revel in the opportunity to showcase their offerings to a global audience.
              </p>
            </div>
          </div>
        </div>
      </div>
  
      <div class="container-fluid mb-5w-100 my-5">
        <div class="row g-5 align-items-start flex-row-reverse">
          <div class="col-md-6 p-4 bg-body p-0 rounded-4">
            <img
              src="../assets/img6.jpg"
              class="img-fluid custom-img-design rounded-4"
              alt="ManEatingImage"
            />
          </div>
          <div class="col-md-6 p-4">
            <div class="">
              <p class="fw-light fs-2 text-end" >
                Furthermore, Restroworld transcends being merely a marketplace; it's a thriving community fostering collaboration, learning, and growth. Through forums, webinars, and knowledge-sharing initiatives, industry experts, aspiring entrepreneurs, and seasoned veterans converge to exchange insights, trends, and best practices, nurturing a culture of continuous improvement.
              </p>
            </div>
          </div>
        </div>
      </div>
  
      <div class="container-fluid mb-5w-100 my-5">
        <div class="row g-5 align-items-start">
          <div class="col-md-6 p-4 text-right bg-body p-0 rounded-4">
            <img
              src="../assets/img7.jpg"
              class="img-fluid custom-img-design rounded-4"
              alt="ManEatingImage"
            />
          </div>
          <div class="col-md-6 p-4">
            <div class="">
              <p class="fw-light fs-2" >
                User experiences within Restroworld have been nothing short of transformative. Restaurant owners express elation at discovering niche vendors offering unique solutions that transcend geographical boundaries. Testimonials flood in, citing increased efficiency, cost-effectiveness, and elevated customer satisfaction resulting from collaborations initiated on the platform.where boundaries cease to exist. As it continues to evolve and innovate, Restroworld stands as a testament to the power of connectivity, community, and visionary entrepreneurship in shaping industries for the better.
              </p>
            </div>
          </div>
        </div>
      </div>
  
      <div class="container-fluid w-100 my-5">
        <div class="row g-5 align-items-start flex-row-reverse">
          <div class="col-md-6 p-4 bg-body p-0 rounded-4">
            <img
              src="../assets/img8.jpg"
              class="img-fluid custom-img-design rounded-4"
              alt="ManEatingImage"
            />
          </div>
          <div class="col-md-6 p-4">
            <div class="">
              <p class="fw-light fs-2 text-end" >
                Vendors, too, share success stories, recounting how Restroworld catapulted their businesses onto a global stage, allowing them to forge partnerships they'd only dreamed of. The platform's intuitive interface, robust search functionalities, and personalized recommendations garner praise, making it a go-to destination for businesses seeking growth and innovation. where boundaries cease to exist. As it continues to evolve and innovate, Restroworld stands as a testament to the power of connectivity, community, and visionary entrepreneurship in shaping industries for the better.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "SignupSecond",
  };
  </script>
  
  <style scoped>
  .custom-signupImage {
    background-color: rgba(245, 245, 245, 0.13);
  }
  .container-fluid img{
    height: 400px; /* Set the desired fixed height for your images */
  width: 100%; /* Allow images to take up the full width of their container */
  object-fit: cover;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 400px 0px;
    }

  

  </style>