<template>
  <div>
    <div
      v-for="i in banners"
      :key="i.src"
      class="img-div"
      id="right-ad-banners"
    >
      <a @click="redirect(i.link)" style="cursor: pointer">
        <img :src="i.src" class="img-fluid bg-cover" alt="Image" />
      </a>
    </div>
  </div>
</template>

    
<script>
export default {
  name: "RightAdBanner",
  data() {
    return {
      banners: [
        {
          src: require("./assets/Ad_Redjinni.png"),
          link: "https://www.redjinni.com/",
        },

        {
          src: require("./assets/Ad_Ibsf.png"),
          link: "https://play.google.com/store/apps/details?id=com.ib.ibsalesforce&hl=en_US",
        },

        {
          src: require("./assets/Ad_Restroworld.png"),
          link: "https://play.google.com/store/apps/details?id=com.ib.restroworld&hl=en_US",
        },

        {
          src: require("./assets/Ad_Redprix.png"),
          link: "https://play.google.com/store/apps/details?id=com.ib.redprix&hl=en_US",
        },

        {
          src: require("./assets/Ad_Red_Restro.png"),
          link: "https://play.google.com/store/apps/details?id=com.redrestro&hl=en_US",
        },
      ],
      single_banner: null,
    };
  },
  methods: {
    redirect(url) {
      window.location.href = url;
    },
  },
  mounted() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((element) => {
        if (element.intersectionRatio > 0) {
          element.target.classList.add("animated-anim");
          observer.unobserve(element.target);
        }
      });
    });

    this.$nextTick(() => {
      this.$el
        .querySelectorAll("#right-ad-banners")
        .forEach((div) => observer.observe(div));
    });
  },
};
</script>

    
<style scoped>
.img-div {
  width: 100%;
  border-radius: 6px;
  margin: 6rem auto;
  overflow: hidden;
  transition: box-shadow 0.3s;
}

.animated-anim {
  animation: fadeInRight;
  animation-duration: 1.2s;
}

.img-div img {
  height: 24.5rem;
  transition: transform 0.5s;
}

.img-div:hover {
  box-shadow: 0px 0px 0.5px #bab9b9;
}

.img-div:hover,
.img-div img {
  transform: scale(1.018);
}

@media only screen and (max-width: 992px) {
  .img-div {
    display: none;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .img-div img {
    height: 19rem;
    transition: transform 0.5s;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1400px) {
}
</style>
