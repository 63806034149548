<template>
  <div class="w-100 py-2 position-relative bottom-0 footer-div">
    <!-- <h3 class="text-white fs-5 text-center my-3">Footer Of GarbaJockey</h3> -->
  </div>
</template>

<script>
export default {
  name: "FooterComp",
};
</script>

<style scoped>
.footer-div {
  background-color: #1f5899;
  margin-top: 8rem;
  height: 50px;
}
</style>