<template>
  <header
    class="navbar navbar-inverse navbar-fixed-top bs-docs-nav navbar-expand-lg navbar-light fixed-top bg-light text-dark shadow-lg bg-info rounded"
    role="banner"
  >
    <div class="container">
      <div class="navbar-header me-auto">
        <h2 class="rw-heading-text">
          <b
            ><router-link to="/Home"
              ><span class="space1" style="color: #004898">Restro</span
              ><span class="space1" style="color: #c41e16"
                >world</span
              ></router-link
            ></b
          >
        </h2>
      </div>
      <div class="title_message">
        <p style="margin: 0 0 0 24px; font-weight: 700; color: #004898">
          First Global Restaurant Community
        </p>
      </div>

      <b-navbar-nav class="ml-auto">
        <b-nav-form class="">
          <Search class="d-none d-md-block" />

          <!-- <a href="#" class="p-2 text-center ms-auto menu-icon show" id="dropdownMenu3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" data-bs-toggle="dropdown"><span class="dot-count bg-warning"></span><i class="feather-bell font-xl text-current"></i></a> -->

          <a
            class="p-0 ms-3 menu-icon d-none d-sm-block"
            v-bind:href="'/Home/User'"
            ><img src="../assets/home-icon.png" alt="user" class="w40 rounded"
          /></a>
          <b-nav-item-dropdown right>
            <template #button-content>
              <a class="p-0 ms-1 menu-icon rounded-xxl">
                <img
                  :src="user.profile_photo"
                  alt="user"
                  height="40"
                  class="w40 rounded"
                  v-if="user.profile_photo"
                />
                <img
                  src="../assets/img/profile-no-img.99d6b3a5.png"
                  alt="user"
                  height="40 rounded"
                  class="w40"
                  v-else
                />
              </a>
            </template>
            <b-dropdown-item
              v-bind:href="'/profile/' + user.id"
              class="custom-show"
              >View Profile</b-dropdown-item
            >
            <b-dropdown-item
              class="custom-show"
              v-bind:href="'/VendorShow/' + user.user_type"
              v-if="
                (user.is_subscribed && user.user_type === '7') ||
                user.user_type === '6'
              "
              >Vendor</b-dropdown-item
            >
            <b-dropdown-item
              class="custom-show"
              v-bind:href="'/VendorShow/' + user.user_type"
              v-if="user.is_subscribed && this.user.user_type === '8'"
              >Vendor</b-dropdown-item
            >
            <b-dropdown-item
              class="custom-show"
              v-bind:href="'/ManpowerShow/'"
              v-if="user.is_subscribed"
              >Manpower</b-dropdown-item
            >
            <b-dropdown-item
              class="custom-show"
              v-bind:href="'/FranchiseeShow/'"
              v-if="user.is_subscribed"
              >Franchise Provider</b-dropdown-item
            >
            <b-dropdown-item
              v-bind:href="'/EditProfile/' + users.restaurant.id"
              v-if="users.restaurant"
              >My Account</b-dropdown-item
            >
            <b-dropdown-item
              v-bind:href="
                '/ManpowerListings/ManpowerEditProfile/' + users.manpower.id
              "
              v-else-if="users.manpower"
              >My Account</b-dropdown-item
            >
            <b-dropdown-item
              v-bind:href="
                '/VendorListing/VendorEditProfile/' + users.vendor.id
              "
              v-else-if="users.vendor"
              >My Account</b-dropdown-item
            >

            <b-dropdown-item v-bind:href="'/IBTalks'">IB Talks</b-dropdown-item>
            <b-dropdown-item v-bind:href="'/AllBlogs'">Blogs</b-dropdown-item>
            <b-dropdown-item v-bind:href="'/tutorialPage'"
              >Tutorials</b-dropdown-item
            >

            <b-dropdown-item v-bind:href="'/Home/User/setting'"
              >Settings</b-dropdown-item
            >
            <b-dropdown-item v-bind:href="'/Home/User/privecy'"
              >Privacy</b-dropdown-item
            >
            <b-dropdown-item v-bind:href="'/Home/User/help'"
              >Help</b-dropdown-item
            >
            <b-dropdown-item href="javascript:void(0)" @click="handleClick"
              >Sign Out</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-nav-form>
      </b-navbar-nav>
    </div>
  </header>
</template>

<script>
import Search from "../components/GlobleSearch/Search.vue";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "Nav",
  components: { Search },
  data() {
    return {
      users: "",
    };
  },

  methods: {
    async handleClick() {
      await axios.get("logout");
      localStorage.clear();
      this.$store.dispatch("user", null);
      this.$router.push("/");
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    this.users = JSON.parse(localStorage.getItem("UserDetails"));
  },
};
</script>

<style scoped>
.rw-heading-text {
  font-size: 40px !important;
  margin: unset !important;
}

.custom-show {
  display: none;
}

@media (max-width: 732px) {
  .custom-show {
    display: block !important;
  }
}

@media screen and (max-width: 767px) {
  .rw-heading-text {
    font-size: 30px !important;
  }
}

@media screen and (max-width: 576px) {
  .container {
    margin: auto 0;
  }
  .title_message {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .title_message {
    display: none;
  }
}

@media screen and (min-width: 767px) and (max-width: 992px) {
  .title_message {
    display: none;
  }
}

@media screen and (min-width: 993px) and (max-width: 1201px) {
  .title_message {
    display: none;
  }
}
</style>