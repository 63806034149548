<template>
    <div class="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>
        The privacy policy contains personal information collected on our website, it's not used by any third party or doesn't apply on any other site. It's a legal statement that justifies the limitless information gathered at a particular point of time and is secured. By accessing the website, you're agreed with our professional and personal care services, moreover, believe in our non-disclosure policies.
      </p>
      <p>
        Furthermore, you're agreed to the terms contained in our statements or any document incorporated by reference, it does not matter if you're registered with a digital agency network or not.
      </p>
      <p>
        By disagreeing with our protocols, we have the right to hide our services and conditions from you so that you'll not be in the efficient mode of enjoying our benefits.
      </p>
      <p>
        In addition to this, the privacy policy of RESTROWORLD covers the confidential information we collect from our visitors and users of (site URL) and also our related research and reviews system. You're free to switch your click if you're not agreed with our services and concerning agreements.
      </p>
  
      <h2>Your Rights</h2>
      <p>
        You're the owner of the market, therefore, you have certain rights to take your quick decision if you find any negative vibes while sharing your confidential info to our website under the General Data Protection Regulation (GDPR). For your preference here's the list of our queries:
      </p>
  
      <h3>Information about you</h3>
      <p>
        While glancing at your interest we generally ask about your participation to use our services, such as:
      </p>
      <ul>
        <li>At the time of creating your account you're asked to provide us your name, address, and email address, plus to use our website location friendly we ask to provide additional information such as your location, bio, industry, picture, and cellphone number, also if you feel someone by your information has created your account you can ask us to delete it.</li>
        <li>For the review procedure, we'll ask for the company's name, address, official mail address, and this information will not be public.</li>
        <li>If you're interested in purchasing our services then after your general interest we'll display your brand's name as a client at our website.</li>
      </ul>
  
      <h3>Information about others</h3>
      <p>
        By information about others we mean to provide your clients or business partner's information such as name, address, email address, contact number to support our reference check, the info is only asked for review procedure in the future.
      </p>
  
      <h2>How Do We Use Your Information</h2>
      <p>
        The automated system is accessible to collect your information after your provided information to customize our website. We collect the same for your convenience as you're once registered you're free from disturbances pings, the details are used to offer you stand out services, provide you the effective content, to communicate you via email or verbal mode, plus sends you imperative emails to make you aware about efficient features and services lists, and a lot more.
      </p>
  
      <h2>Communications exchange</h2>
      <p>
        When you registered yourself as our member we'll be sharing all qualitative and beneficiary details for your study to stimulate your keen interest via email. You're free to change your personal details while visiting our website or contacting us at (email address).
      </p>
  
      <h2>Shares the information by Facebook and Google</h2>
      <p>
        If you allow for PII (Personally Identifiable Information) with third parties which is said as a lookalike audience marketing then we may share your business information for this type of marketing including Facebook, Google Analytics, Google Search Console, Google AdWords, or any other Google tools.
      </p>
  
      <h2>Security</h2>
      <p>
        Your personal data information is our responsibility hence we conduct several parameters for the security but ensure that no security measures are 100% accurate or healthier and no method of transmission is transparent. We do a lot to secure your details but as we said nothing is accurate or we cannot provide you any warranty so do it at your own risk.
      </p>
      <p>
        You're free to report security violations to us at our mail ID (address).
      </p>
  
      <h2>Our Email Policy</h2>
      <p>
        We'll not send, sell, or trade your email address to a third party without your permission so feel safe with us. If you receive an email from RESTROWORLD asking for your credit cards, banking details, or any other financial information, just neglect and ignore the mail as we never ask via email for this information.
      </p>
  
      <h2>How To Reach Us For Privacy Concerns</h2>
      <p>
        If you've any query regarding the procedures, practices, or in the use of our website, or would like to bring in any changes then feel free to contact us via mail or phone.
      </p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PrivacyPolicy',
  };
  </script>
  <style scoped>
  .privacy-policy {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  h3 {
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 15px;
  }


  
  
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  /* Add additional styles as needed */
  </style>
  