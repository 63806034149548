<template>
  <main class="custom-res-navbar">
    <!-- Navbar for larger screens -->
    <div class="container-xl d-none d-md-flex justify-content-center w-100">
      <nav
        class="d-flex w-100 justify-content-around align-items-center fixed-top big-screen-nav"
        style="background: white; padding: 1.5rem 0rem"
      >
        <div class="row d-flex align-items-center">
          <div class="col-sm-5 custom-logo-text">
            <RouterLink
              to="/"
              class="d-flex justify-conent-center align-items-center"
            >
              <img
                src="./assets/Scroll Group 48.png"
                alt="logo of RedPrix"
                class="w-100"
                style="object-fit: contain"
              />
            </RouterLink>
          </div>

          <div class="col-sm-7 custom-text">
            <h3 class="fw-bold fs-4 text-primary mb-0">
              First Global Restaurant Community
            </h3>
          </div>
        </div>

        <ul class="d-flex gap-2">
          <li
            class="list-unstyled fs-4 p-2 bg-white rounded custom-hover"
            @click="logout"
          >
            <button
              class="text-decoration-none text-black fw-lighter"
              style="border: none; background: none"
              @click="logout"
            >
              Log-in
            </button>
          </li>

          <RouterLink
            to="/RegisterNew"
            class="text-decoration-none text-black fw-lighter"
          >
            <li class="list-unstyled fs-4 p-2 bg-white rounded custom-hover">
              Sign-up
            </li>
          </RouterLink>

          <RouterLink
            to="/dummyIBTalks"
            class="text-decoration-none text-black fw-lighter"
          >
            <li class="list-unstyled fs-4 p-2 bg-white rounded custom-hover">
              IB-Talks
            </li>
          </RouterLink>

          <RouterLink
            to="/dummyAllBlogs"
            class="text-decoration-none text-black fw-lighter"
          >
            <li class="list-unstyled fs-4 p-2 bg-white rounded custom-hover">
              Blogs
            </li>
          </RouterLink>

          <RouterLink
            to="/dummyTutorialPage"
            class="text-decoration-none text-black fw-lighter"
          >
            <li class="list-unstyled fs-4 p-2 bg-white rounded custom-hover">
              Tutorials
            </li>
          </RouterLink>
        </ul>
      </nav>
    </div>

    <!-- Navbar for smaller screens -->
    <nav
      class="navbar navbar-expand-md navbar-light d-md-none p-3 justify-content-between"
      style="background-color: white"
    >
      <div class="container">
        <RouterLink to="/" class="navbar-brand">
          <img
            src="./assets/Scroll Group 48.png"
            alt="logo of RedPrix"
            class="w-100 custom-logo"
            style="object-fit: contain"
          />
        </RouterLink>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="toggleNavbar"
        >
          <!-- <span class="navbar-toggler-icon"></span> -->
          <div
            class="d-flex align-items-center justify-content-center profile-img-div"
          >
            <img
              :src="defaultProfileImg"
              alt="Default-Profile"
              class="profile-img"
            />
          </div>
        </button>
      </div>

      <!-- Use the SideNavigation component -->
      <!-- :class="{ isOpenSideBar: isSideNavigationOpen }" -->
      <DummySideNavBar
        :sidebarOpenFlag="isSideNavigationOpen"
        @closeSidebar="closeSidebar"
      />
    </nav>
  </main>
</template>

<script>
import DummySideNavBar from "./DummySideNavBar.vue";
export default {
  name: "DummyNavBar",
  data() {
    return {
      isSideNavigationOpen: false,
      defaultProfileImg: require("./assets/profile-no-img.99d6b3a5.99d6b3a5.png"),
    };
  },

  created() {
    this.lodData();
  },

  methods: {
    lodData() {
      this.token = localStorage.getItem("authToken");
      //   console.log("this is home page show token", this.token);
    },

    logout() {
      localStorage.removeItem("authToken");
      this.$router.push("/login");
    },

    toggleNavbar() {
      this.isSideNavigationOpen = true;
    },

    closeSidebar() {
      this.isSideNavigationOpen = false;
    },
  },
  components: { DummySideNavBar },
};
</script>

<style scoped>
.navbar-toggler {
  width: 40px;
  height: 40px;
  padding: 1px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-img-div {
  width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: 50%;
}

.profile-img {
  width: 100%;
  border-radius: 50%;
}

.isOpenSideBar {
  left: 0;
}

.custom-res-navbar {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10000;
}
.custom-hover {
  border: 1px solid transparent;
  cursor: pointer;
}

.custom-hover:hover {
  /* background-color: rgb(253, 251, 249) !important; */
  border: 1px solid black;
}

@media (max-width: 457px) {
  .custom-logo {
    width: 75% !important;
  }
}

@media (max-width: 992px) {
  main {
    overflow: visible;
  }

  .big-screen-nav {
    justify-content: space-between !important;
    padding: 1.5rem 1rem !important;
  }
  .custom-logo-text {
    width: 100%;
  }
  .custom-text {
    display: none;
  }
}

@media (max-width: 767px) {
  .custom-res-navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }
}
</style>
